<template>
  <div class="outer-wrapper">
    <div class="title-wrapper">
      <span>{{title}}管理</span>
    </div>
    <div class="inner-wrapper">
      <div class="editor-wrapper">
        <Tabs>
          <TabPane :label="'现有'+title" icon="ios-warning">
            <query-table
              :columnsFormatData="columnsFormatData"
              :askData="code"
              :refreshData="update_count"
              main_width="1260px"
            ></query-table>
          </TabPane>
          <Button slot="extra" @click="openModal('create')">添加</Button>
        </Tabs>
      </div>
    </div>
    <Modal
      v-model="modalSw.create"
      width="1200"
      :mask-closable="false"
      :styles="{top:0}"
      @on-cancel="closeModal('create')"
    >
      <p slot="header" class="sc-header">
        <Icon type="ios-list-box" />
        <span>新增{{ title }}</span>
      </p>
      <div class="sc-content-wrapper">
        <div class="form-wrapper">
          <Form
            ref="create"
            :model="newItem"
            :rules="ruleValidate"
            :label-width="180"
          >
          <FormItem label="请选择类型：" prop="category">
              <Select v-model="newItem.category" style="width: 200px">
                <Option
                  v-for="(item,index) in option.category"
                  :value="item.value"
                  :key="index"
                  >{{ item.text }}({{item.value}})</Option
                >
              </Select>
            </FormItem>
          <FormItem label="标题：" prop="title">
              <Input v-model="newItem.title" placeholder="" />
            </FormItem>
            <FormItem label="简述：" prop="description">
              <Input type="textarea" v-model="newItem.description" placeholder="" />
            </FormItem>
            <FormItem label="发布日期：" prop="pubdate">
              <input
                type="date"
                v-model="newItem.pubdate"
                style="width: 200px"
              />
            </FormItem>
            <FormItem label="语言版本：" prop="lang">
              <Select
                v-model="newItem.lang"
                style="width: 200px"
              >
                <Option
                  v-for="(item,index) in option.lang"
                  :value="item.value"
                  :key="index"
                  >{{ item.text }}</Option
                >
              </Select>
            </FormItem>
            <FormItem :label="title+'首图上传：'" prop="imgFile">
              <div class="img-outer-wrapper">
                <a
                  v-for="(ele, index) in newItem.accessoryFiles"
                  :key="index + 'img'"
                  class="img-a"
                >
                  <img :src="ele.file_url" alt="" style="width: 200px" />
                  <span @click="delFileByCreate(index)">点击删除</span>
                </a>
                <div class="img-wrapper">
                  <Upload
                    type="drag"
                    action
                    :on-success="handleSuccess"
                    :format="['jpg', 'jpeg', 'png']"
                    :max-size="2048"
                    :on-format-error="handleFormatError"
                    :on-exceeded-size="handleMaxSize"
                    :before-upload="(value) => handleBeforeUploadByCreate(value)"
                    style="
                      width: 100%;
                      outline: none;
                      margin: 0 auto;
                      border: none;
                    "
                  >
                    <div style="width: 100%; min-height: 50px">
                      <p class="operation-text">+</p>
                    </div>
                  </Upload>
                </div>
              </div>
            </FormItem>
            <FormItem label="新闻图文内容：" prop="content" style="width:438px" v-if="modalSw.create">
              <u-editor v-model="newItem.content" :ec="newItem.content" :update="editor_refresh" style="width:438px" key="create"></u-editor>
            </FormItem>
          </Form>
          <Spin fix v-show="loadingStatus"></Spin>
        </div>
      </div>
      <div slot="footer">
        <Button type="primary" @click="handleCreate('create')">提交</Button>
        <Button @click="handleReset('create')" style="margin-left: 8px"
          >重置</Button
        >
        <Button size="large" @click="closeModal('create')">取消</Button>
      </div>
    </Modal>
    <Modal
      v-model="modalSw.alter"
      width="1200"
      :mask-closable="false"
      :styles="{top:0}"
      @on-cancel="closeModal('alter')"
    >
      <p slot="header" class="sc-header">
        <Icon type="ios-list-box" />
        <span>{{title}}修改--{{currentItem.name}}</span>
      </p>
      <div class="sc-content-wrapper">
        <div class="form-wrapper">
          <Form
            ref="alter"
            :model="currentItem"
            :rules="ruleValidate"
            :label-width="150"
          >
          <FormItem label="请选择类型：" prop="category">
              <Select v-model="currentItem.category" style="width: 200px">
                <Option
                  v-for="(item,index) in option.category"
                  :value="item.value"
                  :key="index"
                  >{{ item.text }}({{item.value}})</Option
                >
              </Select>
            </FormItem>
            <FormItem label="标题：" prop="title">
              <Input v-model="currentItem.title" placeholder="" />
            </FormItem>
            <FormItem label="简述：" prop="description">
              <Input type="textarea" v-model="currentItem.description" placeholder="" />
            </FormItem>
            <FormItem label="发布日期：" prop="pubdate">
              <input
                type="date"
                v-model="currentItem.pubdate"
                style="width: 200px"
              />
            </FormItem>
            <FormItem label="语言版本：" prop="lang">
              <Select
                v-model="currentItem.lang"
                style="width: 260px"
              >
                <Option
                  v-for="(item,index) in option.lang"
                  :value="item.value"
                  :key="index"
                  >{{ item.text }}</Option
                >
              </Select>
            </FormItem>
            <FormItem label="新闻首图上传：" prop="imgFile">
              <div class="img-outer-wrapper">
                <a
                  v-for="(ele, index) in currentItem.accessoryFiles"
                  :key="index + 'img'"
                  class="img-a"
                  
                >
                  <img :src="ele.file_url" alt="" style="width: 200px" />
                  <span @click="delFileByAlter(ele,index)">点击删除</span>
                </a>
                <div class="img-wrapper">
                  <Upload
                    type="drag"
                    action
                    :on-success="handleSuccess"
                    :format="['jpg', 'jpeg', 'png']"
                    :max-size="512"
                    :on-format-error="handleFormatError"
                    :on-exceeded-size="handleMaxSize"
                    :before-upload="(value) => handleBeforeUploadByAlter(value)"
                    style="
                      width: 100%;
                      outline: none;
                      margin: 0 auto;
                      border: none;
                    "
                  >
                    <div style="width: 100%; min-height: 50px">
                      <p class="operation-text">+</p>
                    </div>
                  </Upload>
                </div>
              </div>
            </FormItem>
            <FormItem label="新闻图文内容：" prop="content" style="width:438px" v-if="modalSw.alter">
              <u-editor v-model="currentItem.content" :ec="currentItem.content" :update="editor_refresh" style="width:438px" key="alter"></u-editor>
            </FormItem>
          </Form>
        </div>
      </div>
      <div slot="footer">
        <Button size="large" @click="handleAlter('alter')">确定</Button>
        <Button size="large" @click="closeModal('alter')">取消</Button>
      </div>
    </Modal>
    <Modal
      v-model="modalSw.content"
      width="1200"
      :mask-closable="false"
      :styles="{top:0}"
      @on-cancel="closeModal('content')"
    >
      <p slot="header" class="sc-header">
        <Icon type="ios-list-box" />
        <span>{{title}}图文内容--{{currentItem.title}}</span>
      </p>
      <div class="sc-content-wrapper">
        <div class="form-wrapper">
          <div v-html="currentContent"></div>
        </div>
      </div>
      <div slot="footer">
        <Button size="large" @click="closeModal('content')">关闭</Button>
      </div>
    </Modal>
  </div>
</template>
<script>
import queryTable from "@/components/Common/QueryTable.vue";
import uEditor from "@/components/Common/UEditor.vue";
import {manageFuncs} from "@/libs/mixins/ManageFuncs.js";

let userToken = localStorage.getItem("current_user_token");

let authData = JSON.parse(localStorage.getItem("authData"));

function itemFac() {
  return {
    id: 0,
    title:"",
    description:"",
    category:"",
    content:"",
    pubdate:null,
    stat:"",
    lang:"",
    accessoryFiles: [],
  };
}


export default {
  name: "newsManage",
  components: {
    queryTable,
    uEditor
  },
  props: {},
  mixins:[manageFuncs],
  data() {
    return {
      title:"您的行业",
      code:"industries",
      modalSw:{alter:false,create:false,content:false,},
      update_count: 0,
      newItem: itemFac(),
      currentItem: itemFac(),
      currentContent:"",
      editor_refresh:0,
      columnsFormatData: [
        {
          title: "标题",
          key: "title",
          align: "center",
        },
        {
          title: "简述",
          key: "description",
          align: "center",
        },
        {
          title: "发布日期",
          key: "pubdate",
          align: "center",
          width: 120,
        },
        {
          title: "状态",
          key: "stat",
          align: "center",
          width: 120,
        },
        {
          title: "语言版本",
          key: "lang",
          align: "center",
          width: 120,
        },
        {
          title: "操作",
          align: "center",
          width: 180,
          render: (h, params) => {
            return h("div", [
              h(
                "Button",
                {
                  props: {
                    size: "small",
                  },
                  style: { color: "blue" },
                  on: {
                    click: () => {
                      this.currentContent = params.row.content;
                      this.openModal('content');
                    },
                  },
                },
                "内容"
              ),
              h(
                "Button",
                {
                  props: {
                    size: "small",
                  },
                  style: { color: "green" ,marginLeft: "5px" },
                  on: {
                    click: () => {
                      this.itemEdit(params.row);
                    },
                  },
                },
                "修改"
              ),
              h(
                "Button",
                {
                  props: {
                    size: "small",
                  },
                  style: { color: "red", marginLeft: "5px" },
                  on: {
                    click: () => {
                      this.confirmBox(params.row);
                    },
                  },
                },
                "删除"
              ),
            ]);
          },
        },
      ],
      loadingStatus: false,
      ruleValidate: {
        title: [
          {
            required: true,
            type: "string",
            message: "请输入服务标题！",
            trigger: "blur",
          },
        ],
        description: [
          {
            required: true,
            type: "string",
            message: "请输入服务简述！",
            trigger: "blur",
          },
        ],
        lang: [
          {
            required: true,
            type: "string",
            message: "请选择语言版本！",
            trigger: "blur",
          },
        ],
      },
      option: {
      },
    };
  },
  watch: {},
  computed: {},
  created() {
    this.getOption();
  },
  methods: {
    getOption() {
      this.$axios
        .post(this.$url + "/option", {
          userToken: userToken,
          askData: this.code,
        })
        .then((response) => {
          this.option = response.data.res_record;
        });
    },
    handleReset(name) {
      this.$refs[name].resetFields();
    },
    handleSuccess() {},
    handleFormatError(file) {
      this.$Notice.warning({
        title: "文件格式不正确",
        desc: "文件 " + file.name + " 的格式不正确, 请选择 jpg 或 png.",
      });
    },
    handleMaxSize(file) {
      this.$Notice.warning({
        title: "超出文件大小",
        desc: "文件  " + file.name + " 太大, 应不大于500KB.",
      });
    },
    handleBeforeUploadByCreate(file) {
      let tmp = {file_url:"",file_obj:file};
      let objUrl = this.getObjectURL(file);
      if (objUrl) {
        tmp.file_url = objUrl;
      }
      this.newItem.accessoryFiles.push(tmp);
      return false;
    },
    handleBeforeUploadByAlter(file) {
      let tmp = {file_url:"",file_obj:file};
      let objUrl = this.getObjectURL(file);
      if (objUrl) {
        tmp.file_url = objUrl;
      }
      this.currentItem.accessoryFiles.push(tmp);
      return false;
    },
    delFileByCreate(i) {
      this.newItem.accessoryFiles.splice(i, 1);
    },
    delFileByAlter(item,index) {
      if(item.id!==undefined){
        this.$Modal.confirm({
          title: "确认删除附件：",
          content:
            "将会在服务器上同时删除该文件",
          onOk: () => {
            this.delFile(item,'img',index);
          },
          onCancel: () => {},
        });
      }else{
        this.currentItem.accessoryFiles.splice(index, 1);
      }
    },
    delFile(item,category,index) {
      this.$axios
        .post(this.$url + '/' + category, {
          userToken: userToken,
          askData: "del",
          pid: item.id,
        })
        .then((response) => {
          if (response.data.stat === "ok") {
            this.$Message.success("删除成功！");
            this.currentItem.accessoryFiles.splice(index, 1);
            this.update_count++;
          }
        })
        .catch((error) => {
          this.$Message.warning(error + "网络错误！操作失败。");
        });
    },
    handleCreate(name) {
      this.$refs[name].validate((valid) => {
        if (valid) {
          let form = new FormData();
          form.append("userToken", userToken);
          for (let i of this.newItem.accessoryFiles) {
            form.append("file", i['file_obj']);
          }
          this.newItem.accessoryFiles = undefined;
          form.append("askData", "add");
          form.append("newItem", JSON.stringify(this.newItem));
          this.loadingStatus = true;
          let config = {
            headers: { "Content-Type": "multipart/form-data" },
          };
          this.$axios
            .post(this.$url + '/' + this.code, form, config)
            .then((response) => {
              this.loadingStatus = false;
              if (response.data.stat === "ok") {
                this.$Message.success(this.title+"添加成功！");
                // this.update_count++;
                // this.closeModal(name);
                // this.newItem = itemFac();
                location.reload();
              } else {
                this.$Message.error(response.data.error_info);
              }
            })
            .catch((error) => {
              this.loadingStatus = false;
              this.$Message.error(error + "未知错误！");
            });
        } else {
          this.$Message.error("填入信息错误!");
        }
      });
    },
    handleAlter(name) {
      this.$refs[name].validate((valid) => {
        if (valid) {
          let form = new FormData();
          form.append("userToken", userToken);
          form.append("askData", "alter");
          for (let i of this.currentItem.accessoryFiles) {
            if(i['id']===undefined){
              form.append("file", i['file_obj']);
            }
          }
          this.currentItem.accessoryFiles = undefined;
          form.append("currentItem", JSON.stringify(this.currentItem));
          this.loadingStatus = true;
          let config = {
            headers: { "Content-Type": "multipart/form-data" },
          };
          this.$axios
            .post(this.$url + '/' + this.code, form, config)
            .then((response) => {
              this.loadingStatus = false;
              if (response.data.stat === "ok") {
                this.$Message.success(this.title+"修改成功！");
                // this.update_count++;
                // this.closeModal(name);
                location.reload();
              } else {
                this.$Message.error(response.data.error_info);
              }
            })
            .catch((error) => {
              this.loadingStatus = false;
              this.$Message.error(error + "未知错误！");
            });
        } else {
          this.$Message.error("填入信息错误!");
        }
      });
    },
    openModal(label){
      this.modalSw[label] = true;
    },
    closeModal(label) {
      this.modalSw[label] = false;
    },
    confirmBox(value) {
      this.$Modal.confirm({
        title: "确认删除该条内容：",
        content:
          "<p>" +
          "名称：" +
          value.name +
          "</p>",
        onOk: () => {
          this.delItem(value.id);
        },
        onCancel: () => {},
      });
    },
    delItem(id) {
      this.$axios
        .post(this.$url + '/' + this.code, {
          userToken: userToken,
          askData: "del",
          pid: id,
        })
        .then((response) => {
          if (response.data.stat === "ok") {
            this.$Message.success("删除成功！");
            this.update_count++;
          }
        })
        .catch((error) => {
          this.$Message.warning(error + "网络错误！操作失败。");
        });
    },
    itemEdit(value) {
      this.currentItem = itemFac();
      for(let i in this.currentItem){
      if(i!=='accessoryFiles'){
       this.currentItem[i] = value[i];
         }
       }
      if (
            value.img_file &&
            value.img_file.length > 0
          ) {
            for (let i of value.img_file) {
              this.currentItem.accessoryFiles.push({id:i['id'],file_url:this.$fileUrlFac(i['file_url'], authData.sfileRight)});
            }
          }
          this.openModal('alter');
          setTimeout(()=>{this.editor_refresh++},300);
          setTimeout(()=>{this.editor_refresh++},1000);
          
      
    },
  },
};
</script>
<style scoped>
.inner-wrapper {
  width: 100%;
  display: flex;
  display: -webkit-flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: flex-start;
  min-height: 700px;
}

.title-wrapper {
  text-align: center;
  font-size: 20px;
  padding: 16px;
}


.form-wrapper {
  text-align: left;
  padding: 80px 120px 0px 50px;
  min-height: 600px;
  position: relative;
}

.sc-content-wrapper {
  padding: 0 50px;
  color: #333;
  font-size: 14px;
}
.choosed-product-wrapper {
  min-height: 100px;
  padding: 10px 20px;
  border: 1px solid #eee;
  box-shadow: 1px 1px 1px #eee;
  margin: 20px 0;
}

.sc-content-wrapper td:first-child {
  text-align: right;
  padding-right: 10px;
}

.sc-content-wrapper td {
  height: 45px;
  color: #777;
}

.sub-content-wrapper {
  margin: 10px 0;
  border: 1px solid #eee;
  position: relative;
}

.sub-content-title {
  position: absolute;
  left: 0;
  overflow: hidden;
  height: 100%;
  writing-mode: vertical-lr;
  text-align: center;
  background: #f5f5f5;
  letter-spacing: 10px;
  text-indent: 10px;
  padding: 12px 10px;
}

.table-wrapper {
  padding: 22.5px 20px;
  margin-left: 50px;
}

.fast-button {
  cursor: pointer;
}

input::-webkit-input-placeholder {
  color: #ccc;
  text-align: center;
}

input:-ms-input-placeholder {
  color: #ccc;
  text-align: center;
}

input::-moz-placeholder {
  color: #ccc;
  text-align: center;
}
tr.modal-product-choosed-tr {
  border-bottom: 1px solid #ddd;
}

tr.modal-product-choosed-tr:last-child {
  border: none;
}

.img-outer-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: left;
}

.img-wrapper {
  width: 50px;
  min-height: 50px;
  margin: 0 5px;
}

.img-wrapper-inner {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.img-a {
  border: 1px solid #ccc;
  margin: 0 5px;
  height: fit-content;
  position: relative;
}

.img-a span {
  display: none;
  position: absolute;
  font-size: 8px;
  right: 10px;
  top: 5px;
}

.img-a:hover span {
  display: inline-block;
}

input {
  display: inline-block;
  width: 100%;
  height: 32px;
  line-height: 1.5;
  padding: 4px 7px;
  font-size: 14px;
  border: 1px solid #dcdee2;
  border-radius: 4px;
  color: #515a6e;
  background-color: #fff;
  background-image: none;
  position: relative;
  cursor: text;
  transition: border 0.2s ease-in-out, background 0.2s ease-in-out,
    box-shadow 0.2s ease-in-out;
}

input:focus {
  border-color: #57a3f3;
  outline: 0;
  box-shadow: 0 0 0 2px rgba(45, 140, 240, 0.2);
}

input:hover {
  border-color: #57a3f3;
}
</style>